import axios from "axios";

function request(opt) {
    let url = opt.url || "";
    let method = opt.method || "GET";
    let headers = opt.headers || { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
    let data = opt.data || null;
    let params = opt.params || null;

    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: method,
            headers: headers,
            data: data,
            params: params,
        }).then(res => {
            if (res.status === 200) {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}

export default request;