import request from './http';


export const opid_api = (params)=>request({
    url: "apis/consumer/oauth/saop",
    method:"get",
    params
});

export const accesstoken_api = (params)=>request({
    url: "apis/consumer/oauth/access_token",
    method:"get",
    params
});

export const wxuserinf_api = (params)=>request({
    url: "apis/consumer/oauth/wx_userinfo",
    method:"get",
    params
});

export const register_api = (data)=>request({
    url: "apis/consumer/oauth/register",
    headers:{
        "content-type":"application/json;charset=utf-8"
    },
    method:"post",
    data
});
export const userinfo_api = (params)=>request({
    url: "apis/consumer/oauth/userinfo",
    method:"get",
    params
});

export const codeurl_api =(params)=>request({
    url: "apis/consumer/oauth/usercode",
    method:"get",
    params
});

export const orderlist_api =(data)=>request({
    url: "apis/consumer/order/orderlist",
    method:"post",
    headers: {
        "content-type": "application/json;charset=utf-8"
    },
    data
});