<template>
  <div class="App">
    <router-view />
  </div>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}
body,
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  width: 100vw;
  height: 100vh;
   background-color: #f7f8fa;
}
</style>
