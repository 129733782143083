import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Home
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/Order.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue')
  },
  {
    path: "/code",
    name: 'code',
    component: () => import('../views/Code.vue')
  },
  {   
    path: "/neworder",
    name: 'neworder',
    component: () => import('../views/Newoder.vue')
  },
  {   
    path: "/upcard",
    name: 'upcard',
    component: () => import('../views/Upcard.vue')
  },
  {   
    path: "/test",
    name: 'test',
    component: () => import('../views/Test.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
