<template>
  <div class="home">
    <div class="head">
      <div class="conyer">
        <van-image
          class="avater"
          round
          width="6rem"
          height="6rem"
          :src="info.avatar"
        />
        <div class="name">
          <span>{{ info.username }}</span>
          <img
            class="sex"
            :src="info.sex === 1 ? icon.nic : icon.vic"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
    <form action="/"  @click="onSearch">
      <van-search   placeholder="请输入用户姓名/手机号码" />
    </form>
    <div class="fcclas">
      <van-cell
        @click="onClick"
        icon-prefix="haha"
        :icon="icon.ic1"
        title="我的订单"
        is-link
      />
      <div class="orders">
        <div
          class="icos"
          @click="onItemCilk(item.status)"
          v-for="(item, i) in items"
          :key="i"
        >
          <van-icon class="ico" :name="item.icon" /><br />
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="fcbox">
      <router-link to="/upcard"> <van-cell icon="records" title="订单录入" is-link /></router-link>
      <van-cell icon="photo-o" title="验收上传" is-link />
      <van-cell icon="star-o" title="业务推广" is-link />
      <van-cell icon="phone-o" title="技术支持" is-link value="0961 - 2612113" />
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { accesstoken_api, userinfo_api } from "../store/apis";
export default {
  setup() {

    const items = [
      {
        title: "待授权",
        icon: "apps-o",
        status: 2,
      },
      {
        title: "待核查",
        icon: "user-o",
        status: -3,
      },
      {
        title: "征信被拒",
        icon: "failure",
        status:-4,
      },
      {
        title: "待面签",
        icon: "tv-o",
        status: 4,
      },
    ];
    const router = useRouter();
    let openid = window.localStorage.getItem("openid");
    const info = ref({
      authority: 4,
      avatar: "",
      openid: "",
      phone: "",
      uid: 0,
      sex: 1,
      username: "",
    });
    const getusif = async (openId) => {
      let resp = await userinfo_api({"openid":openId});
      if (resp.data) {
        info.value = resp.data;
      } else {
        const host = `${document.location.protocol}//${window.location.host}/register`;
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc26d1e2d331c6228&redirect_uri=${host}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      }
    };
    if (openid) {
      getusif(openid);
    } else {
      const route = useRoute();
      if (route.query.code) {
        accesstoken_api(route.query).then((resp) => {
          if (!resp.errcode) {
            window.localStorage.setItem("openid",resp.openid);
            getusif(resp.openid);
          }
        });
      }
    }
    const onClick = () => {
      router.push({
         path:"/order",
         query:{
           uid:info.value.uid,
           status:2
         }
       });
    };
    const onItemCilk = (status) => {
       router.push({
         path:"/order",
         query:{
           uid:info.value.uid,
           status:status
         }
       });
    };

    const onSearch=()=>{
      console.log('1');
       router.push({
         path:"/search",
       });
    }

    return {
      items,
      onSearch,
      icon: {
        ic1: require("../assets/od.png"),
        nic: require("../assets/n.png"),
        vic: require("../assets/v.png"),
      },
      info,
      onItemCilk,
      onClick,
    };
  },
};
</script>
<style lang="scss" >
.home {
  .head {
    width: 100%;
    height: 200px;
    background-image: url("../assets/wxbc.jpg");
    background-size: cover;
    padding: 10px;
    display: flex;
    justify-content: center;
    .conyer {
      padding-top: 20px;
      .avater {
        border: 2px solid #fff;
      }
      .name {
        margin-top: 5px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 25px;
        .sex {
          width: 20px;
          margin-left: 5px;
        }
      }
    }
  }
  .fcclas {
    background-color: #fff;
    box-shadow: 0 8px 12px #ebedf0;
    .van-icon__image {
      margin-top: 3px;
      font-size: 18px;
    }
    .orders {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      .icos {
        text-align: center;
        font-size: 12px;
        color: #666;
        .ico {
          font-size: 18px;
        }
      }
    }
  }
  .fcbox {
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 8px 12px #ebedf0;
  }
}
</style>
