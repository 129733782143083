import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vant from 'vant';
import 'vant/lib/index.css';


createApp(App)
.use(store)
.use(router)
.use(vant)
.mount('#app')
