import { createStore } from 'vuex'
import {userinf_api} from './apis';

export default createStore({
  state: {

  },
  getters: {
  },
  mutations: {
    get_asstoken (state) {

    }
  },
  actions: {
    get_asstoken ({commit},params) {
      userinf_api(params).then(resp=>{
        console.log(resp);
      })
      
      // context.commit('increment')
    }
  },
 
})
